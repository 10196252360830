<template>
    <b-collapse isNav>
        <b-navbar-nav class="ml-auto d-md-flex">
            <b-nav-form
                v-if="genericSearchEnabled"
                class="mr-2"
                @submit.prevent
            >
                <b-input-group class="input-group-merge">
                    <b-input-group-prepend isText>
                        <feather-icon class="text-muted" icon="SearchIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                        id="search-generic"
                        debounce="1000"
                        :placeholder="searchTermPlaceHolder"
                        @change="onGenericSearchUpdated"
                    />
                </b-input-group>
            </b-nav-form>
            <b-nav-form @submit.prevent>
                <b-button
                    v-if="!hideFilterButton"
                    :aria-expanded="expanded ? 'true' : 'false'"
                    :class="expanded ? 'text-primary' : 'text-secondary'"
                    aria-controls="filter-collapse"
                    class="btn-icon"
                    variant="flat-disabled"
                    @click="$emit('clickExpand')"
                >
                    <feather-icon
                        :badge="activeFiltersCount"
                        icon="FilterIcon"
                        size="28"
                    />
                </b-button>
            </b-nav-form>
        </b-navbar-nav>
    </b-collapse>
</template>

<script>
import _ from "lodash"
import {
    BButton,
    BCollapse,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BNavbarNav,
    BNavForm,
} from "bootstrap-vue"
import DatatableFilterUtil from "@/views/components/utilis-spa/datatable/datatableFilterUtil"

export default {
    name: "DatatableSearch",
    components: {
        BFormInput,
        BInputGroup,
        BInputGroupPrepend,
        BCollapse,
        BNavbarNav,
        BNavForm,
        BButton,
    },
    props: {
        hideFilterButton: {
            type: Boolean,
            required: false,
            default: false,
        },
        searchTermPlaceHolder: {
            type: String,
            required: false,
            default: "",
        },
        expanded: {
            type: Boolean,
            required: true,
            default: false,
        },
        genericSearchEnabled: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    emits: ["onFilterChange", "clickExpand"],
    data() {
        return {
            selectedDatatableFilters:
                this.initialSelectedDatatableFilters || {},
        }
    },
    computed: {
        activeFiltersCount() {
            return Object.values(this.selectedDatatableFilters).filter(
                value =>
                    value &&
                    (typeof value === "object"
                        ? Object.values(value).some(x => !_.isEmpty(x))
                        : !_.isEmpty(value))
            ).length
        },
    },
    methods: {
        onGenericSearchUpdated(value) {
            const searchFilter = DatatableFilterUtil.createFilter.search()
            this.$emit("onFilterChange", [{ ...searchFilter }, value])
        },
    },
}
</script>
