<template>
    <div>
        <b-navbar>
            <b-navbar-brand tag="h3">{{ title }}</b-navbar-brand>

            <slot name="buttons"></slot>

            <DatatableSearch
                :expanded="isFilterExpanded"
                :genericSearchEnabled="genericSearchEnabled"
                :searchTermPlaceHolder="searchTermPlaceHolder"
                :hideFilterButton="hideFilterButton"
                @clickExpand="isFilterExpanded = !isFilterExpanded"
                @onFilterChange="onFilterChangeHandler"
                @filterUpdated="$emit('filterUpdated')"
            ></DatatableSearch>
        </b-navbar>
        <slot
            name="advancedSearch"
            :expanded="isFilterExpanded"
            :onFilterChange="onFilterChangeHandler"
        ></slot>
    </div>
</template>

<script>
import { BNavbar, BNavbarBrand } from "bootstrap-vue"
import DatatableSearch from "@/views/components/utilis-spa/datatable/DatatableSearch.vue"
import DatatableFilterUtil from "@/views/components/utilis-spa/datatable/datatableFilterUtil"
import _ from "lodash"

const { TYPES } = DatatableFilterUtil

export default {
    name: "DatatableHeader",
    components: { DatatableSearch, BNavbarBrand, BNavbar },
    props: {
        title: {
            type: String,
            required: false,
            default: "",
        },
        hideFilterButton: {
            type: Boolean,
            required: false,
            default: false,
        },
        searchTermPlaceHolder: {
            type: String,
            required: false,
            default: "",
        },
        genericSearchEnabled: {
            type: Boolean,
            required: false,
            default: false,
        },
        /**
         * For use when component is being created and you need pre selected filters
         * To be used on future function, when we map filter from url
         */
        initialSelectedDatatableFilters: {
            type: Object,
            required: false,
            default() {
                return Object.create({})
            },
        },
    },
    data() {
        return {
            isFilterExpanded: false,
            selectedDatatableFilters:
                this.initialSelectedDatatableFilters || {},
        }
    },
    methods: {
        /**
         * Adds currentValue to the changedFilter and emits filterUpdated event
         * @param filter {DatatableFilterUtil.TYPEDEFS.DATATABLE_FILTER_SCHEMA}
         * @param value
         */
        onFilterChangeHandler([filter, value]) {
            // Default value for simple filters
            let currentValue = value

            if (filter.type === TYPES.LIST) {
                currentValue = Array.isArray(value)
                    ? _.flatMap(value, "value")
                    : [value.value]
            } else if (filter.type === TYPES.SELECT_ASYNC) {
                if (currentValue) currentValue = Array.of(value)
            } else if (filter.type === TYPES.DATE_RANGE) {
                if (
                    !_.get(currentValue, "startDate") ||
                    !_.get(currentValue, "endDate")
                )
                    return
            }
            const filterData = { ...filter, currentValue }

            const updatedDatatableFilter = this.updateFilteredValues(
                filterData
            )

            this.selectedDatatableFilters = updatedDatatableFilter

            this.$emit("filterUpdated", updatedDatatableFilter)
        },
        updateFilteredValues(newFilterData) {
            const updatedFilterData = _.cloneDeep(
                this.selectedDatatableFilters
            )
            const newValue = newFilterData.currentValue

            const hasValue =
                newValue || (Array.isArray(newValue) && newValue.length !== 0)

            if (!hasValue) {
                delete updatedFilterData[newFilterData.name]
                return updatedFilterData
            }

            if (
                newFilterData.type === TYPES.LIST &&
                newFilterData.operation === "delete"
            ) {
                const valueToRemove = newValue[0]
                const updatedListValues = updatedFilterData[
                    newFilterData.name
                ].currentValue.filter(value => value !== valueToRemove)

                if (!updatedListValues.length) {
                    delete updatedFilterData[newFilterData.name]
                    return updatedFilterData
                }

                updatedFilterData[
                    newFilterData.name
                ].currentValue = updatedListValues
                return updatedFilterData
            }

            updatedFilterData[newFilterData.name] = newFilterData

            return updatedFilterData
        },
    },
}
</script>
