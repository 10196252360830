<template>
    <b-collapse id="filter-collapse" :visible="expanded">
        <b-card :title="$t('common.advancedFilters')">
            <b-row>
                <b-col
                    v-for="filter in availableDatatableFilters"
                    :key="filter.name"
                    class="mb-1"
                    md="3"
                    xl="3"
                >
                    <template v-if="filter.type === 'list'">
                        <b-form-group :label="filter.label">
                            <v-select
                                :id="filter.name"
                                :name="filter.name"
                                :dir="
                                    $store.state.appConfig.isRTL ? 'rtl' : 'ltr'
                                "
                                :options="filter.options"
                                :placeholder="filter.placeholder"
                                multiple
                                @option:selected="
                                    $emit('onFilterChange', [
                                        {
                                            ...filter,
                                            ...{ operation: 'add' },
                                        },
                                        ...arguments[0],
                                    ])
                                "
                                @option:deselected="
                                    $emit('onFilterChange', [
                                        {
                                            ...filter,
                                            ...{ operation: 'delete' },
                                        },
                                        ...arguments[0],
                                    ])
                                "
                            />
                        </b-form-group>
                    </template>

                    <template v-else-if="filter.type === 'text'">
                        <b-form-group :label="filter.label">
                            <b-form-input
                                :name="filter.name"
                                debounce="1000"
                                :placeholder="filter.placeholder"
                                @change="
                                    $emit('onFilterChange', [
                                        { ...filter },
                                        ...arguments,
                                    ])
                                "
                            />
                        </b-form-group>
                    </template>

                    <template v-else-if="filter.type === 'select-async'">
                        <formulateInput
                            :label="filter.label"
                            :name="filter.name"
                            :placeholder="filter.placeholder"
                            :apiData="filter.apiData"
                            type="vue-select-async"
                            validation="optional"
                            @input="
                                $emit('onFilterChange', [
                                    { ...filter },
                                    ...arguments[0],
                                ])
                            "
                            @update:options="
                                $emit('onFilterChange', [
                                    {
                                        ...filter,
                                        ...{ operation: 'add' },
                                    },
                                    ...arguments[0],
                                ])
                            "
                        />
                    </template>

                    <template v-else-if="filter.type === 'date'">
                        <formulate-input
                            :value="''"
                            :label="filter.label"
                            :name="filter.name"
                            :placeholder="filter.placeholder"
                            type="custom-date"
                            @update:date="
                                $emit('onFilterChange', [
                                    { ...filter },
                                    ...arguments[0],
                                ])
                            "
                        >
                        </formulate-input>
                    </template>

                    <template v-else-if="filter.type === 'date-range'">
                        <formulate-input
                            :label="filter.startDate.label"
                            :name="filter.startDate.name"
                            :placeholder="filter.placeholder"
                            type="date-range"
                            @update:date="
                                changeStartDateFilter(arguments[0], filter)
                            "
                        >
                        </formulate-input>
                        <formulate-input
                            :label="filter.endDate.label"
                            :name="filter.endDate.name"
                            :placeholder="filter.placeholder"
                            type="date-range"
                            @update:date="
                                changeEndDateFilter(arguments[0], filter)
                            "
                        >
                        </formulate-input>
                    </template>
                </b-col>
            </b-row>
        </b-card>
    </b-collapse>
</template>

<script>
import vSelect from "vue-select"
import {
    BCard,
    BCol,
    BCollapse,
    BFormGroup,
    BFormInput,
    BRow,
} from "bootstrap-vue"

export default {
    components: {
        BCard,
        BFormGroup,
        BFormInput,
        BRow,
        BCol,
        BCollapse,
        vSelect,
    },
    props: {
        availableDatatableFilters: {
            type: Object,
            required: true,
        },
        expanded: {
            type: Boolean,
            required: true,
            default: false,
        },
    },
    emits: ["onFilterChange"],
    data() {
        return {
            selectedDatatableFilters:
                this.initialSelectedDatatableFilters || {},
            dateRange: {
                endDate: "",
                startDate: "",
            },
        }
    },
    methods: {
        changeStartDateFilter(date, filter) {
            if (date === "Invalid date" || date === "") return

            this.dateRange.startDate = date
            this.$emit("onFilterChange", [
                { ...filter },
                {
                    startDate: this.dateRange.startDate,
                    endDate: this.dateRange.endDate,
                },
            ])
        },
        changeEndDateFilter(date, filter) {
            if (date === "Invalid date" || date === "") return

            this.dateRange.endDate = date
            this.$emit("onFilterChange", [
                { ...filter },
                {
                    startDate: this.dateRange.startDate,
                    endDate: this.dateRange.endDate,
                },
            ])
        },
    },
}
</script>
